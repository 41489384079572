const Card = ({ className, title = "Card 1", text = "Card 1 Text", link = ""}) => {
    return (
        <a href={link} className="relative">
            <div className={`card flex flex-col text-center justify-end ${className}`}>
                <h1>{title}</h1>
                <p>{text}</p>
            </div>
        </a>
    );
};

export default Card;