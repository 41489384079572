const Hero = () => {
    return (
        <div className="hero text-center space-y-4">
            <h3 className="text-4xl multicolortext">made for work and play</h3>
            <div className="max-w-full sm:px-16 md:px-12 lg:px-8 xl:px-36">
                <div className="text-box">
                    <p> 
                    Coding is a passion and I am lucky to be working for a fantasic
                    digital marketing agency based in Birmingham. I left project
                    management within the construction industy, went traveling and now
                    this is what I do.</p>
                    <p>I am a Codeworks graduate, full stack
                    engineer and a very messy cook.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Hero;