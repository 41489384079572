import { useState } from "react";
import { useDispatch } from "react-redux";
import { setAuth } from "./redux/authSlice";
import { useNavigate } from "react-router-dom";
// import BlobButton from "./BlobButton";
import Card from "./Components/Card";
import Navbar from "./Components/Navbar";
import Hero from "./Components/Hero";

function Layer1() {
  // const navigate = useNavigate();
  // const dispatch = useDispatch();
  // const [guess, setGuess] = useState("");

  console.log("what you looking in here for?")

  // function handleChange(event) {
  //   setGuess(event.target.value);
  // }

  // function handleSubmit(event) {
  //   event.preventDefault();
  //   if (guess === process.env.REACT_APP_PASSWORD) {
  //     dispatch(setAuth(true));
  //   }
  //   setGuess("");
  //   navigate("/wedding");
  // }

  return (
    <>
    <Navbar />
    <Hero />
      <div className="main text-center">
        <div className=" flex-row text-center p-5 space-y-4"> 
            <div className="text-box">
              <h2>My thoughts on Vibe Coding </h2>
              <p>Firstly, how exciting - opening up development to everyone who can quickly build an app for themselves and have a play around. I think the shroud of developers being almighty gods of the internet is pretty much over now! Oooh your a developer that must be so hard you must be so clever - well yes I am clever but I dont think that means knowing how to make websites. Someone actually said to me once - "Aye - thats dead posh that is aye it".  I think it means i know how to us AI to help me speed up development. Is AI taking my job - well in some instances yes BUT developers will always be needed to steer the ship</p>
            </div>

            {/* <div className="text-box">
              <p>How fun is this button!</p>
              <BlobButton
                text={"get your passport"}
                handleClick={() => {console.log("hi")}}
              ></BlobButton>
              <p>It doesnt do anything its just pretty</p>
            </div> */}

            {/* <div className="text-box">
              <form onSubmit={handleSubmit}>
                <p>Wedding Website</p>
                <input
                  className="bg-blue-50 border border-blue-300"
                  type="password"
                  value={guess}
                  onChange={handleChange}
                  disabled={false}
                  placeholder="Enter Password"
                  label="Wedding Webiste"
                  />
                <button
                  className="bg-indigo-500 hover:bg-sky-700 text-white font-bold ms-3 py-1 px-4 rounded"
                  type="submit"
                  onClick={() => {
                    console.log("click");
                  }}
                  >
          
                  Submit
                </button>
              </form>
            </div> */}
 
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6 mx-12 py-6">
              <Card className="wedding-card" title="Wedding Website" text="HEY - its invite only..."/>
              <Card className="travel-card" title="Travel Blog" text="Grab your passport" link="/blog"/>
              <Card className="gran-card" title="Who is Anna's Gran?" text="An Anagram game with a grandma twist" link="/annas-gran"/>
            </div>

            <div className="orange-text-box">
              <h2>I Can Solve A Rubix Cube </h2>
              <p> A strange feeling - watched a video - learnt the algorithms  - and now I can just do it. Feels kind of underwelming - the mystery is gone. Guess im just going to have to work on getting my time down. How the professionals do it in under 3 seconds is insane</p>
            </div>
        </div>
      </div>
    </>
  );
}

export default Layer1;
